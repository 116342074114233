body {
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 300px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.mismatch-or-empty{
  border: 1px solid red;
}
.login-pg-input-email .input-wrapper input {
  border: 1px solid red;
}
h3 {
}
.validation-wrapper{
  border:none;
}
.validation-wrapper label::before{
  top:0px !important;
}
.validation-wrapper label {
  position: relative;
  padding-left: 8px;
}
.custom-label-wrapper-ui::before{
  content: "*";
  position: absolute;
  top: -3px;
  left: 0;
  font-size: 16px;
  color: red;
}
.custom-label-wrapper-ui{
  color:#7C8092;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  padding-left: 8px;
}

.validation-wrapper label::after {
  content: "*";
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 16px;
  color: red;
}
.password-input div span label {
  position: relative;
  padding-left: 8px;
}

.password-input div span span label::after {
  display: none;
}
.password-input div span label::after {
  content: "*";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color: red;
}
label {
  font-size: 11px;
  font-weight: 400px;
}
.select-address-modal .select-address.selected {
  background: rgba(238, 241, 245, 0.2);
  border: 1px solid #e6e6e6;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}

.category-modal .category-modal-btn.btn-save {
  background: #2589ff !important;
  color: #fff !important;
  padding: 7px 24px !important;
}
.select-address-modal .select-address {
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.select-address-modal .modal-title {
  font-size: 18px;
  margin: 0 2px;
}
.select-address-modal .details {
  display: flex;
  flex-wrap: wrap;
}
.select-address-modal .details div {
  display: flex;
  flex-direction: column;
  min-width: 174px;
}
.select-address-modal h6 {
  font-size: 18px;
  color: #4e5969;
}
.select-address-modal .details div span:nth-child(1) {
  font-size: 0.75rem;
  padding-bottom: 8px;
  color: #4e5969;
}
.select-address-modal .details div span:nth-child(2) {
  font-size: 0.875rem;
  padding-bottom: 8px;
}
.icon-circle-check:before {
  content: "\e930";
  color: #1565d8;
}
.icon-check-success .path1:before {
  content: "\e92e";
  color: #009c34;
}
.icon-check-success .path2:before {
  content: "\e92f";
  color: #fff;
  margin-left: -1em;
}
.grid-column-gap-36 {
  grid-column-gap: 36px;
}
.category-modal .modal-content .category-modal-wrapper h3 {
  margin-bottom: 24px;
}
.store-modal-wrapper h2 {
  margin-bottom: 0;
}
 .stores-container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
}
.category-modal .modal-footer .btn-save:hover {
  background-color: transparent;
  color: #2589ff;
}
.category-modal .modal-footer .category-modal-btn {
  border-radius: 4px;
}
.category-modal .modal-footer .btn-save {
  background: transparent;
  color: #2589ff;
  border: none;
  /* padding: 0px 32px !important; */
  margin-top: -6px !important;
}
.cancel-modal .icon-exclamation h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  color: ;
  margin-bottom: 0;
  text-align: center;
}
.cancel-modal .icon-exclamation {
  margin-bottom: 20px;
  font-size: 64px;
  text-align: center;
}
.category-modal .modal-footer a {
  margin-right: 1px !important;
  margin-top: 0px;
}

.category-modal .modal-footer {
  display: flex;
  /* flex-direction: row-reverse; */
  border-top: 0px;
  padding-top: 0px !important;
  padding-left: 23px;
  padding-right: 22px;
  padding-bottom: 19px !important;
  border-radius: 4px;
}

.category-modal .modal-content .modal-body {
  padding: 24px !important;
}
.category-modal .modal-content {
  width: 100%;
  max-width: 372px;
  margin: auto;
  margin-top: 186px;
}


.tooltip-keepa-main {
  width: 100%;
  max-width: 610px;
  opacity: 1 !important;
  position: fixed !important;
  bottom: 15px !important;
  right: 15px !important;
  transform: none !important;
  align-items: end;
  display: flex;
  top: auto !important;
  z-index: 1;
  pointer-events: none;
}
.tooltip-keepa img {
  max-width: 153%;
  transition: 0.3s all ease-in;
}
.tooltip-keepa img:hover {
  max-width: 100%;
}
.tooltip-keepa-main .tooltip-inner {
  max-width: 400px;
  background-color: #ffffff;
  padding: 0;
  box-shadow: 0px 0px 15px #cacaca;
}
.tooltip-keepa-main .tooltip-arrow {
  display: none;
}
.tooltip-keepa-main .tooltip-arrow:before {
  border-left-color: #cdc7c7;
}
.cursor-pointer {
  cursor: pointer;
}
.password-input-wrapper {
  margin-top: 16px;
}
.user-page-password-modal.modal.show .modal-footer button {
  padding: 8px 24px;
  background: #2476fa;
  color: #fff;
  border: 1px solid #2476fa;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper .modal-credit-card-heading {
    font-size: 24px;
    margin: 16px 0 !important;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper .modal-credit-card-text {
    padding: 0 16px;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper .modal-footer button:nth-child(2) {
    background: #2476fa;
    color: #fff;
    border: 1px solid #2476fa;
    padding: 4px 16px;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper {
  text-align: center;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper
  .modal-credit-card-text{
    padding: 0px;
  }
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper
  .alert-pop-add-card {
  display: block;
  margin: auto;
  font-size: 60px;
  color: red !important;
}
.fade.sc-jSUZER.gSmaua.payment-success-modal.modal.show
  .modal-body
  .add-card-popup-wrapper
  .check-rounded {
  display: block;
  margin: auto;
  font-size: 60px;
  color: green;
}
.add-card-popup-wrapper h1 {
  margin-bottom: 8px !important;
}
.add-card-popup-wrapper svg {
  font-size: 60px;
  color: #D40001;
}
.remove-stericks span label::after {
  display: none;
}
.remove-stericks span label {
  padding-left:0 !important;
}
.modal-ui-wrapper{
  display: flex;
  grid-gap: 16px;
}
.modal-ui-wrapper input{
  width: 240px;
}
.modal-ui-wrapper label {
  display: block;
  text-align: left;
}
.add-sub-user-modal .modal-dialog.modal-dialog-centered {
  max-width: 600px !important;
}
.address-wrapper label::before {
  display: none !important;
}
.modal-backdrop {
  z-index:2 !important ;
 }
 .add-sub-user-modal {
  z-index: 2;
}
.links-ui-wrapper a {
  text-decoration: none;
}
span.ag-icon.ag-icon-menu {
  cursor: pointer;
  font-size: 16px;
}

  .note-text-wrapper {
    cursor: pointer;
    line-break: anywhere;
    overflow-wrap: anywhere;
    white-space: normal;
}
h6.edit-note-modal-text {
  text-align: left;
  font-weight: 600;
}
.edit-modal-wrapper.modal .modal-content{
  padding: 16px;
}
.edit-modal-wrapper.modal .modal-footer{
  padding-bottom: 0px;
  padding-right: 0;
}
.edit-modal-wrapper.modal .modal-footer .btn-save{
  height:auto;
  background: transparent;
  color: #0e6ffd;
}
.edit-modal-wrapper.modal .modal-body{
  padding: 0 !important;
}
textarea.text-area-note-modal {
  width: 100%;
  height: 130px;
  border: 2px solid #ddd;
  padding: 8px;
  border-radius: 4px;
}
.export-modal .p-4.modal-body label.ant-radio-wrapper {
  font-size: 17px;
  font-weight: 500;
}
.export-modal .p-4.modal-body h6 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}
.export-modal .p-4.modal-body {
  text-align: left;
  padding: 0px !important;
}
.menu-icon{
  margin-left: 8px;
  font-size:24px;
}
.image-ui-wrapper{
  max-width: 400px !important;
}
.image-ui-wrapper .tooltip-inner {
  max-width: 400px !important;
}
.quantity-buttons-wrapper span{
  user-select: none;
}
.quantity-buttons-wrapper svg{
  font-size: 24px;
}
.quantity-buttons-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.delete-buttons-wrapper svg {
  font-size: 16px;
  color: red;
}
.modal-delete-wrapper .ant-modal-content {
  background: #fff;
  border-radius: 8px;
}